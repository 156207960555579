import ProjectCard from "@components/project-card/project-card-component";
import React from "react";
import { Wrapper } from "./project-card-collection-styles";
import { useProjectCollection } from "@queries/use-project-collection";

const SUBTITLE_BG_COLORS = [
  "rgb(183, 76, 43)",
  "rgb(216, 46, 74)",
  "rgb(0, 110, 144)",
];

const ProjectCardCollection = () => {
  const { getProjectsFrontmatter } = useProjectCollection();

  const projects = getProjectsFrontmatter();
  return (
    <Wrapper>
      {projects.map((project, index) => {
        return (
          <ProjectCard
            key={project.id}
            bgColor={SUBTITLE_BG_COLORS[index % SUBTITLE_BG_COLORS.length]}
            slug={project.slug}
            subtitle={project.title}
            heroImage={project.heroImage}
          />
        );
      })}
    </Wrapper>
  );
};

export default ProjectCardCollection;
