import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Headline from "@components/headline/headline-component";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import ProjectCardCollection from "@components/project-card/project-card-collection";
import React from "react";
import Seo from "@components/seo";
import { useImage } from "@queries/use-image";

export default function Projetos() {
  const { getFluidImageNameAndExtension } = useImage();
  const seoImage = getFluidImageNameAndExtension("meeting-company", "jpg");

  return (
    <Layout isHeroAvailable={false}>
      <FullBleed>
        <Headline
          title="A treebo projeta. Esta é a nossa visão."
          description="Conhece aqui todos os projetos que englobam o universo treetree2. É nisto que nos revemos."
          bgColor="#0080a7"
          imageFilename="puzzles"
        />
      </FullBleed>

      <EmptySpace desktop={{ margin: 100 }} mobile={{ margin: 50 }} />

      <ProjectCardCollection />

      <EmptySpace desktop={{ margin: 130 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="TreeTree2 - Projetos que mudam o país"
        description="Temos vários projetos na área da Ciência que estimulam o desenvolvimento intelectual e criativo das crianças e dos jovens."
        image={seoImage}
      />
    </Layout>
  );
}
