import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-auto-rows: 400px;
  grid-column-gap: 30px;
  grid-row-gap: 40px;

  @media (min-width: 769px) and (max-width: 1010px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-auto-rows: 320px;

    grid-row-gap: 30px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(162px, 1fr));
    grid-auto-rows: 180px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, minmax(158px, 1fr));
    grid-auto-rows: 180px;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }
`;
